<template>
  <div>
    <template v-if="appName ==='FlipPay'">
      <div @click="handleLogoLink" class="site-logo">
        <img :src="flipLogo" alt="Flippay" />
      </div>
    </template>
    <template v-else-if="appName==='RelloPay'">
      <div @click="handleLogoLink" class="site-logo">
        <img :src="relloLogo" alt="RelloPay" />
      </div>
    </template>
  </div>
</template>
<script>

export default {
  data() {
    return {
      relloLogo: require('@/assets/images/logo/rellopay-logo.svg'),
      flipLogo: require('@/assets/images/logo/flippay-logo.svg'),
      appName: process.env.VUE_APP_NAME
    }
  },
  methods: {
    handleLogoLink() {
      if (this.$route.name !== "dashboard") {
        this.$router.push({ name: "dashboard" });
      } else {
        window.location.reload();
      }
    },
  },
}
</script>
