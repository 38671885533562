<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.fullName">
    <vs-dropdown class="cursor-pointer" vs-trigger-click>
      <div class="con-img ml-3">
        <S3ImageGenerator
          v-if="activeUserInfo.profileImage"
          :is-base64="false"
          :document="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          :customClass="'profile-image-circle-nav'"
          :key="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
        />
        <span v-else class="rounded-full shadow-md cursor-pointer profile-img-name">
          {{ userName }}
        </span>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 w-full cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'admin-view-profile' })">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li class="flex w-full py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { clearLocalStorage } from "../../../../helper/localStorage.js";
import { EventBus } from "../../../../mixins/event-bus";
import { mapActions } from "vuex";
import S3ImageGenerator from "../../../../../src/views/components/S3ImageGenerator";

export default {
  components: {
    S3ImageGenerator
  },

  data() {
    return {
      leavePopUpAction: "default",
    };
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    userName() {
      let names = this.activeUserInfo.fullName.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
  },

  methods: {
    ...mapActions("admin", ["logoutAdmin"]),
    removeToken() {
      if ( localStorage.getItem("accessToken") && typeof localStorage.getItem("redirectTo") === "string" && (localStorage.getItem("redirectTo") === "undefined" || localStorage.getItem("redirectTo") === "admin-login")) {
        $cookies.remove("rememberMe");
        $cookies.remove("rememberMeToken");
        $cookies.remove("password");
        clearLocalStorage();
      }
    },

    logout() {
      this.leavePopUpAction = "default";
      if (localStorage.getItem("formFieldUpdated") && localStorage.getItem("formFieldUpdated") === 'no') {
        this.removeToken();
        this.$router.push("/login").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
  },

  created() {
    this.leavePopUpAction = "default";
    EventBus.$on("leavePopUpTrigger", action => {
      this.leavePopUpAction = action;
    });
  },

  watch: {
    leavePopUpAction: {
      async handler(updatedValue, previousValue) {
        if (updatedValue && updatedValue === 'yes') {
          this.removeToken();
        }
      },
      flush: "post",
      immediate: true
    }
  }
};
</script>
