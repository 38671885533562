/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
let adminMenu = [
  {
    url: "/dashboard",
    name: "Home",
    slug: "dashboard",
    icon: "HomeIcon",
    i18n: "Dashboard"
  },
  {
    url: "/partners",
    name: "Merchant",
    slug: "partners",
    icon: "UserIcon",
    i18n: "Merchant"
  },
  {
    url: "/cards",
    name: "Cards",
    slug: "cards",
    icon: "CreditCardIcon",
    i18n: "Cards"
  },
  {
    url: "/legals",
    name: "Legals",
    slug: "legals",
    icon: "HelpCircleIcon",
    i18n: "Legals"
  },
  {
    url: "/paylaterplans",
    name: "PayLaterProductPlans",
    slug: "paylater",
    icon: "AlertCircleIcon",
    i18n: "Pay Later Products"
  },
  {
    url: "/email-templates",
    name: "Templates",
    slug: "email-templates",
    icon: "UserIcon",
    i18n: "Templates"
  },
  {
    url: "/settings",
    name: "Settings",
    slug: "settings",
    icon: "ToolIcon",
    i18n: "Settings"
  },
  {
    url: "/notification-verify",
    name: "notification-verify",
    slug: "notification-verify",
    icon: "SettingsIcon",
    i18n: "Notification Verify"
  }
];

export { adminMenu };
